import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/index.css';

function Index() {
  return (
    <main>
      <Helmet>
        <title>The Crepe Las Vegas</title>
      </Helmet>
      <h1>Coming soon</h1>
      <p>Email us at <a
          href="mailto:hello@charliesmaids.com"
          target="_blank"
          rel="noreferrer noopener"
        >
          hello@charliesmaids.com
        </a>
      </p>
      <br />
    </main>
  );
}

export default Index;
